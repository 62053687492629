import { useQuery } from "react-query"
import apiFetch from "../api"
import { Link } from "react-router-dom";

const NewsArchive = () => {
  const {data: podcasts} = useQuery(["podcasts"], async () => {
    return await apiFetch("/api/podcasts/");
  }, { keepPreviousData: true });
  return (
    <div>
      <h2>News</h2>
      <div class name="grid grid-cols-1">
        <ul>
          {podcasts?.results && podcasts?.results?.map(p => (
            <li key={p.id}>
              <Link to={`/news/${p.id}`}>
                {p.title || "Untitled"} <span className="italic text-gray-400">{p.id}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default NewsArchive;
