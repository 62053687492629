import apiFetch from "./api";

const storeToken = (token) => {
    localStorage.setItem('token', JSON.stringify(token));
};

const getToken = () => {
    const tokenStr = localStorage.getItem('token');
    if (tokenStr) {
        return JSON.parse(tokenStr);
    }
    return null;
};

const removeToken = () => {
    localStorage.removeItem('token');
}


let tokenLock = false;
const tokenQueue = [];

// handle newToken event
window.addEventListener('newToken', (e) => {
    tokenLock = false;
    tokenQueue.forEach((promise) => promise.resolve());
    tokenQueue.length = 0;
});

const verifyToken = async (retry=0) => {
    const token = getToken();
    if (token) {
        try {
            await apiFetch("/auth/jwt/verify/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ token: token.access }),
            });
            return true;
        } catch (e) {
            if (retry < 5) {
                return verifyToken(retry + 1);
            }
            console.log('Failed to verify token', e);
            removeToken();
        }
    }
    return false;
}

const authenticate = async (username, password) => {
    const token = await apiFetch("/auth/jwt/create/", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
    });
    storeToken(token);
    // notify other tabs that the user has logged in
    window.dispatchEvent(new Event('newToken'));
};

const authenticateGoogle = async (response) => {
    const token = await apiFetch(`/api/auth/connect/google/`, {
        method: "POST",
        body: JSON.stringify({ ...response}),
        headers: {
            "Content-Type": "application/json",
        },
    });
    storeToken(token);
    // notify other tabs that the user has logged in
    window.dispatchEvent(new Event('newToken'));
}

const refreshToken = async () => {
    if (tokenLock) {
        return new Promise((resolve, reject) => {
            console.log('token locked, queuing request');
            tokenQueue.push({ resolve, reject });
        });
    } else {
        console.log('got token locked');
        tokenLock = true;
    }

    try {
        console.log('refreshing token');
        const refreshToken = getToken().refresh
        const token = await apiFetch("/auth/jwt/refresh/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ refresh: refreshToken }),
        });
        storeToken({ refresh: refreshToken, ...token });
        window.dispatchEvent(new Event('newToken'));
        // resolve all queued requests
        tokenQueue.forEach((promise) => promise.resolve());
        // clear queue
        tokenQueue.length = 0;
    } catch (e) {
        console.error('Failed to refresh token', e);
        removeToken();
        tokenQueue.forEach((promise) => promise.reject());
        tokenQueue.length = 0;
    }
}

export { storeToken, getToken, authenticate, removeToken, refreshToken, verifyToken, authenticateGoogle };
