import React, { forwardRef } from 'react';

const Select = forwardRef(({ options, ...props }, ref) => (
  <select {...props} ref={ref} className="h-7 p-1 w-full bg-white border border-gray-300 rounded">
    {options.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </select>
));

export default Select;
