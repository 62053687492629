import { useQuery } from "react-query"
import apiFetch from "../api"
import { useParams } from "react-router-dom";
import { useMemo } from "react";
import ArticleCard from "../Components/ArticleCard";
import Podcast from "../Components/Podcast";

const News = () => {
  const {feedId} = useParams();
  const {data: podcasts} = useQuery(["podcasts"], async () => {
    return await apiFetch("/api/podcasts/?ordering=-created_at");
  }, { keepPreviousData: true, enabled: !feedId });
  const {data: feeds} = useQuery(["feeds"], async () => {
    return await apiFetch("/api/feeds/");
  }, { keepPreviousData: true });

  const { data: articles } = useQuery(["articles", feedId], async () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayString = yesterday.toISOString();
    return await apiFetch(`/api/articles/?feed=${feedId}&created_at__gte=${yesterdayString}&ordering=-created_at`);
  }, { enabled: !!feedId });

  const currentFeed = useMemo(() => {
    if (!feeds || !feedId) return null;
    return feeds?.results?.find(f => f.id.toString() === feedId) || {};
  }, [feeds, feedId]);

  const currentPodcast = useMemo(() => {
    if (!podcasts || feedId) return null;
    return podcasts?.results?.[0] || {};
  }, [podcasts, feedId]);

  const topNews = useMemo(() => {
    return currentPodcast?.articles;
  }, [currentPodcast]);

  console.log("topNews", topNews);

  return (
    <div>
      <h2>{currentFeed ? currentFeed.title : "Top Stories"}</h2>
      {currentPodcast && (
        <Podcast id={currentPodcast.id} />
      )}
      <div className="grid gap-4 grid-cols-1 lg:grid-cols-2">
        {topNews && topNews?.map(p => (
          <ArticleCard key={p.id} article={p} />
        ))}
      </div>
      <div className="grid gap-4 grid-cols-1 lg:grid-cols-2">
        {articles?.results && articles?.results?.map(p => (
          <ArticleCard key={p.id} article={p} />
        ))}
      </div>
    </div>
  )
}

export default News;
