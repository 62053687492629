import React from "react";


const Item = ({ activeTab, setActiveTab, tab, children }) => (
    <li className="me-2">
        <span onClick={() => setActiveTab(tab.id)} className={`${activeTab === tab.id ? "active text-primary-600 border-primary-600" : "border-transparent"} cursor-pointer inline-block p-3 pt-1 border-b-2 rounded-t-lg hover:text-primary-600 hover:border-primary-300`}>{children}</span>
    </li>
)

const Tabs = ({tabs, activeTab, setActiveTab, children}) => (
    <div className="flex text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
        <ul className="flex-1 flex flex-wrap -mb-px">
            {tabs.map(tab => <Item key={tab.id} tab={tab} activeTab={activeTab} setActiveTab={setActiveTab}>{tab.name}</Item>)}
        </ul>
        {children}
    </div>
)

export default Tabs;