import { useMutation } from "react-query";
import apiFetch from "../api";
import { useState } from "react";


const FileUpload = ({value, onChange}) => {
  const [error, setError] = useState(null);

  const types = ['text/csv'];
  const fileUploadMutation = useMutation(async (file) => {
    const fileName = file.name;
    const signedUpload = await apiFetch('/api/files/', {
      method: 'POST',
      body: JSON.stringify({
        file_name: fileName,
        file_type: file.type,
      }),
    });
    const {url, fields} = signedUpload.data;
    const formData = new FormData();
    for (const key in fields) {
      formData.append(key, fields[key]);
    }
    formData.append('file', file);
    const uploadResp = await fetch(url, {
      method: 'POST',
      body: formData,
    });
    if (uploadResp.ok) {
      const proccessFile = await apiFetch(`/api/files/${signedUpload.file}/process/`, {
        method: 'POST',
      });
      const fileId = proccessFile.file;
      onChange(fileId);
    } else {
      setError('Error uploading file');
    }

  });
  const changeHandler = (e) => {
    let selected = e.target.files[0];
    if (selected && types.includes(selected.type)) {
      setError('');
      fileUploadMutation.mutate(selected);
    } else {
      setError('Please select a CSV file');
    }
  };

  if (value) {
    return null;
  }

  return (
    <div>
      Upload
      <input type="file" onChange={changeHandler} />
      <div className="output">
        {error && <div className="error">{error}</div>}
      </div>
    </div>
  );
}

export default FileUpload;
