import { useMutation, useQuery, useQueryClient } from "react-query"
import apiFetch from "../api"
import { Link, useNavigate } from "react-router-dom";

const ChatList = () => {
    const { data: chats } = useQuery(["chats"], async () => {
        return await apiFetch("/api/chats/");
    }, { keepPreviousData: true });

    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const createChat = useMutation(
        async () => {
            return await apiFetch(`/api/chats/`, {
                method: "POST",
                body: JSON.stringify({
                    name: "New Chat",
                })
            });
        },
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries(["chats"]);
                navigate(`/chats/${data.id}`);
            }
        }
    )

    return (
        <div>
            <h2>Chats</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
                <ul>
                    {chats?.results && chats?.results?.map(chat => (
                        <li key={chat.id}>
                            <Link to={`/chats/${chat.id}`}>
                                {chat.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            {chats?.results?.length === 0 && <div className="text-center">No chats found</div>}
            <button onClick={createChat.mutate}>Start New</button>

        </div>
    )
}

export default ChatList;
