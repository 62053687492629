import { useQuery, useQueryClient } from "react-query"
import { verifyToken } from "../auth";

const useAuthentication = () => {
    const client = useQueryClient();
    const isAuthenticated = useQuery("isAuthenticated", async () => await verifyToken());
    const clearAuthentication = () => {
        client.invalidateQueries("isAuthenticated");
    } 

    return { isAuthenticated, clearAuthentication };
};

export default useAuthentication;
