import React, { useMemo, useState } from 'react';
import { FaFilter, FaLayerGroup } from 'react-icons/fa';
import { MdAdd, MdClear, MdDragIndicator, MdExpandLess, MdExpandMore, MdJoinLeft } from 'react-icons/md';
import { TbMathFunction } from 'react-icons/tb';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { processColumns } from '../QueryEngine';
import Select from './Select';
import Input from './Input';
import TextBtn from './buttons/TextBtn';
import IconBtn from './buttons/IconBtn';

const FilterComponent = ({ model, control, name, register }) => {
  const options = useMemo(() => {
    if (!model) {
      return [];
    }
    return model.map(({field, headerName}) => ({
      value: field,
      label: headerName || field,
    }));
  }, [model]);
  return (
    <div className="flex">
      <div className="mr-2">
        <Select {...register(`${name}.field`)} options={options}/>
      </div>
      <div className="mr-2">
        <Select {...register(`${name}.operator`)} options={[
          { value: 'eq', label: '=' },
          { value: 'neq', label: '!=' },
          { value: 'gt', label: '>' },
          { value: 'lt', label: '<' },
          { value: 'gte', label: '>=' },
          { value: 'lte', label: '<=' },
          { value: 'contains', label: 'Contains' },
          { value: 'ncontains', label: 'Not Contains' },
        ]} />
      </div>
      <div className="w-full">
        <Input {...register(`${name}.value`)} />
      </div>
    </div>
  );
};

const FunctionComponent = ({ model, control, name, register }) => {
  return (
    <div>
      <div>
        Name
      </div>
      <div className="mb-1">
        <Input {...register(`${name}.name`)} />
      </div>
      <div>
        Expression
      </div>
      <div>
        <Input {...register(`${name}.expression`)} />
      </div>
    </div >
  );
};

const GroupComponent = ({ model, control, name, register }) => {
  const options = useMemo(() => {
    if (!model) {
      return [];
    }
    return model.map(({field, headerName}) => ({
      value: field,
      label: headerName || field,
    }));
  }, [model]);

  const { fields: keys, append: appendKey, remove: removeKey } = useFieldArray({
    control,
    name: `${name}.keys`,
  });

  const {fields: aggregations, append: appendAggregation, remove: removeAggregation} = useFieldArray({
    control,
    name: `${name}.aggregations`,
  });

  return (
    <div className="">
      {keys.map((key, index) => (
        <div key={key.id} className="flex mb-1">
          <div className="grow">
            <Select {...register(`${name}.keys.${index}`)} options={options} />
          </div>
          <div>
            <TextBtn onClick={() => removeKey(index)}>
              <MdClear />
            </TextBtn>
          </div>
        </div>
      ))}
      <div className="text-right">
        <TextBtn onClick={() => appendKey(options[0].value)}>
          <MdAdd />
        </TextBtn>
      </div>
      <div>
        Aggregations
      </div>
      {aggregations.map((aggregation, index) => (
        <div key={aggregation.id} className="flex">
          <div className="mr-2">
          <Select {...register(`${name}.aggregations.${index}.expression`)} options={[
            { value: 'sum', label: 'Sum' },
            { value: 'avg', label: 'Average' },
            { value: 'count', label: 'Count' },
            { value: 'min', label: 'Min' },
            { value: 'max', label: 'Max' },
          ]} />
          </div>
          <div className="grow">
          <Select {...register(`${name}.aggregations.${index}.field`)} options={options} />
          </div>
          <TextBtn onClick={() => removeAggregation(index)}>
            <MdClear />
          </TextBtn>
        </div>
      ))}
      <div className='text-right'>
        <TextBtn onClick={() => appendAggregation({
          expression: 'sum',
          field: options[0].value,
        })}>
          <MdAdd />
        </TextBtn>
      </div>
    </div>
  );
};


const Step = ({ model, control, name, register, onRemove }) => {
  const stepType = useWatch({
    control,
    name: `${name}.type`,
  });
  const Component = useMemo(() => {
    switch (stepType) {
      case 'filter':
        return FilterComponent;
      case 'group':
        return GroupComponent;
      case 'function':
        return FunctionComponent;
      default:
        return () => stepType;
    }
  }, [stepType]);
  return (
    <div className='w-full bg-white p-2 rounded mb-2'>
      <input type="hidden" {...register(`${name}.type`)} />
      <div className="flex item-center mb-2">
        <div className="grow font-bold text-gray-800">
          {stepType}
        </div>
        <div className="flex-none">
          <TextBtn onClick={onRemove}>
            <MdClear />
          </TextBtn>
        </div>
      </div>
      <div>
          <Component model={model} control={control} register={register} name={name} />
      </div>
    </div>
  )
};

const Query = ({ name, model, onChange, register, control, handleSubmit, watch }) => {
  
  const [show, setShow] = useState(true);

  const { fields, append, remove } = useFieldArray({
    control,
    name: `${name}.steps`,
  });
  console.log('Sheet Name', name);
  
  const steps = watch(`${name}.steps`);
  const stepsJson = JSON.stringify(steps);

  const columns = useMemo(() => {
    if (!model) {
      return [];
    }
    if (!steps) {
      return [model];
    }
    return processColumns(model, steps);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model, stepsJson]);

  return (
    <div className="w-full p-2 relative mb-3 md:mb-0">
      <div className="flex items-center" onClick={() => setShow(!show)}>
        <div className="-ml-3">
          < TextBtn>
            {show ? <MdExpandLess /> : <MdExpandMore />}
          </TextBtn >
        </div >
      </div>
      {
        show && (
          <>
            <div className="my-3 ">
              <div className='w-full bg-white p-2 rounded mb-2'>
                <div className="grow font-bold text-gray-800">
                  Title
                </div>
                <Input {...register(`${name}.title`)} />
              </div>
            </div>
            <div className="max-h-[calc(100vh-130px)] overflow-auto">
              {fields.map((item, index) => (
                <Step key={item.index} model={columns[index]} control={control} register={register} name={`${name}.steps.${index}`} onRemove={() => { remove(index) }} />
              ))}
              {fields.length === 0 && (
                <div className="p-3 bg-white border border-slate-400 border-dashed rounded text-center text-gray-500">
                  Add a step to get started
                </div>
              )}
            </div>
            <div className="mt-3">
              <div className="flex flex-wrap mb-4 mt-3 w-100 content-center justify-around">
                <div className="flex">
                  <IconBtn onClick={() =>
                    append({ type: 'filter' })
                  }>
                    <FaFilter />
                  </IconBtn>
                  <IconBtn onClick={() => append({ type: "group" })}>
                    <FaLayerGroup />
                  </IconBtn>
                  <IconBtn onClick={() => append({ type: "function" })}>
                    <TbMathFunction />
                  </IconBtn>
                  <IconBtn onClick={() => append({ type: "join" })}>
                    <MdJoinLeft />
                  </IconBtn>
                </div>
              </div>
            </div>
          </>
        )
      }
    </div >
  );
}

export default Query;
