import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import apiFetch from "../api";

const Deals = () => {
    const today = (new Date()).toISOString().split('T')[0];
    const limit = 30;
    const [page, setPage] = useState(0);
    const dealGridRef = useRef(null);
    const { data: dealData } = useQuery(["deals", today, page, limit], async () => apiFetch(`/api/deals/?until__gte=${today}&limit=${limit}&offset=${page * limit}`), { keepPreviousData: true, enabled: !!today });
    const currencyFormatter = (() => {
        const formatter = new Intl.NumberFormat('en-CA', {
            style: 'currency',
            currency: 'CAD',
            minimumFractionDigits: 2
        })
        return (price) => price ? formatter.format(price) : 'N/A'
    })()
    const onPage = (page) => {
        // scroll to deals grid
        if(dealGridRef?.current) {
            dealGridRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        setPage(page);
    }
    const lastPage = Math.ceil(dealData?.count / limit) - 1;
    return (
        <div>
            <h2>Deals</h2>
            <div ref={dealGridRef} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
            {dealData && dealData.results.map(deal => (
                <div key={deal.id} className="p-2">
                    <div className="w-68 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <img className="h-72 w-68 object-cover rounded-t-xl" src={deal?.product?.image_url} alt="" />
                        <div className="p-5 h-48">
                            <span className="text-sm font-medium text-gray-400 dark:text-gray-300">{deal?.product?.brand}</span><br />
                            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white line-clamp-2">{deal?.product?.name}</h5>
                            <span className="text-lg font-bold text-primary-600 dark:text-gray-300">{currencyFormatter(deal?.price)} </span>
                            <span className="text-sm font-medium line-through text-gray-400 dark:text-gray-300 strike"> {currencyFormatter(deal?.before_price)} </span><br />
                            <span className="text-sm font-medium text-red-400 dark:text-gray-300">ends {deal?.until} </span><br />
                        </div>
                    </div>
                </div>
            ))}
            </div>
            {dealData?.results?.length === 0 && <div className="text-center">No deals found</div>}
            {dealData?.results?.length > 0 && (
                <div className="my-4 w-full flex justify-center">

                    <nav>
                        <ul className="flex items-center -space-x-px h-8 text-sm">
                            {[...Array(Math.ceil(dealData?.count / limit)).keys()].map((p) => (
                                <li key={p} className="page-item">
                                    <button className={`${page === p ? "bg-primary-600 text-white" : "bg-white text-primary-500 hover:bg-primary-100 hover:text-gray-700"} ${p === lastPage ? "rounded-e-lg " : ""} ${p === 0 ? "border-e-0 rounded-s-lg " : ""}flex items-center justify-center px-3 h-8 leading-tight border border-gray-300`} onClick={() => onPage(p)}>{p + 1}</button>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>

            )}
        </div>
    )
};

export default Deals;

