import { compile } from "mathjs";

type DataType = 'string' | 'number' | 'date' | 'datetime' | 'percent' | 'currency';

type Row = {
  [key:string]: string | number | Date;
}

type Table = Array<Row>;

type QueryStep = {
  type: 'filter' | 'group' | 'function' | 'join';
}

type Column = {
  field: string;
  headerName: string;
  type: DataType;
}

type FilterStep = QueryStep & {
  type: 'filter';
  field: string;
  operator: string;
  value: string | number | Date;
}

type Aggregation = {
  field: string;
  expression: string | 'count' | 'sum' | 'avg' | 'min' | 'max';
}

type GroupStep = QueryStep & {
  type: 'group';
  keys: Array<string>;
  aggregations: Array<Aggregation>;
}

type FunctionStep = QueryStep & {
  type: 'function';
  name: string;
  expression: string;
  returnType: DataType;
}

type JoinStep = QueryStep & {
  type: 'join';
  table: string;
  on: string;
}

const ModelColumns = class {
  processFilter(columns: Array<Column>, step: FilterStep): Array<Column>  {
    return columns;
  }

  processGroup(columns: Array<Column>, step: GroupStep): Array<Column>  {
    if (!step.keys || step.keys.length === 0) {
      return columns;
    }
    return [
      ...columns.filter((column: Column) => step.keys.includes(column.field)),
      ...step?.aggregations?.map<Column>((aggregation: Aggregation) => {
        return {
          field: `${aggregation.field}_${aggregation.expression}`,
          headerName: `${aggregation.expression} of ${aggregation.field}`,
          type: 'number'
        };
      }) || []
    ];
  }

  processFunction(columns: Array<Column>, step: FunctionStep): Array<Column> {

    return [...columns, {
      field: step.name,
      headerName: step.name,
      type: step.returnType
    }]

  }

  processJoin(columns: Array<Column>, step: JoinStep): Array<Column>  {
    // TODO: implement join
    return columns;
  }

  processColumns(baseColumns: Array<Column>, steps: Array<QueryStep>) {
    return steps.reduce<Column[][]>((columns: Array<Array<Column>>, step: QueryStep) => {
      // get last step columns
      const lastColumns = columns[columns.length - 1];
      let newColumns: Array<Column> = [];
      switch (step.type) {
        case 'filter':
          newColumns = this.processFilter(lastColumns, step as FilterStep);
          break;
        case 'group':
          newColumns = this.processGroup(lastColumns, step as GroupStep);
          break;
        case 'function':
          newColumns = this.processFunction(lastColumns, step as FunctionStep);
          break;
        case 'join':
          newColumns = this.processJoin(lastColumns, step as JoinStep);
          break;
        default:
          newColumns = lastColumns;
      }
      return [...columns, newColumns];
    }, [baseColumns]);
  }
}

export const processColumns = (baseColumns: Array<Column>, steps: Array<QueryStep>) => {
  return new ModelColumns().processColumns(baseColumns, steps);
}

const QueryEngine = class {

  processFilter(table: Table, columns: Column[], step: FilterStep): Table {
    return table.filter((row: Row) => {
      let value = row[step.field];
      const column = columns.find(c => c.field === step.field);
      let right = step.value;
      if (column?.type === "number") {
        value = parseFloat(value as string);
        right = parseFloat(right as string);
      }
      switch (step.operator) {
        case 'eq':
          return value === right;
        case 'neq':
          return value !== right;
        case 'gt':
          return value > right;
        case 'lt':
          return value < right;
        case 'gte':
          return value >= right;
        case 'lte':
          return value <= right;
        case 'contains':
          return value.toString().includes(step.value.toString());
        case 'ncontains':
          return !value.toString().includes(step.value.toString());
        default:
          return false;
      }
    });
  }

  processGroup(table: Table, columns: Column[], step: GroupStep): Table {
    const keyFn = (row: Row) => step.keys.map((key: string) => row[key]).join(':');
    const groups = table.reduce<{ [key: string]: Row[] }>((groups: { [key: string]: Row[] }, row: Row) => {
      const key = keyFn(row);
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(row);
      return groups;
    } , {});
    return Object.keys(groups).map<Row>((key: string) => {
      const rows = groups[key];
      const newRow: Row = {};
      step.keys.forEach((key: string) => {
        newRow[key] = rows[0][key];
      });
      step.aggregations.forEach((aggregation: Aggregation) => {
        const values: number[] = rows.map((r: Row) => {
          const val = r[aggregation.field];
          if (typeof val === 'number') {
            return val;
          }
          if (typeof val === 'string' && !isNaN(parseFloat(val))) {
            return typeof val === 'string' ? parseFloat(val) : val;
          }
          if (val instanceof Date) {
            return val.getTime();
          }
          return 0;
        });
        const newField = `${aggregation.field}_${aggregation.expression}`;
        switch (aggregation.expression) {
          case 'count':
            newRow[newField] = values.length;
            break;
          case 'sum':
            newRow[newField] = values.reduce((acc: number, val: number) => acc + val, 0);
            break;
          case 'avg':
            newRow[newField] = values.reduce((acc: number, val: number) => acc + val, 0) / values.length;
            break;
          case 'min':
            newRow[newField] = Math.min(...values as number[]);
            break;
          case 'max':
            newRow[newField] = Math.max(...values as number[]);
            break;
          default:
            newRow[newField] = 0;
        }
      });
      return newRow;
    });
  }

  processFunction(table: Table, columns: Column[], step: FunctionStep): Table {

    let expression = step.expression
    const variableMap: { [key: string]: string; } = {};
    const variableNames = Array.from({length: 26}, (_, i) => String.fromCharCode('a'.charCodeAt(0) + i));
    columns.forEach((column: Column, idx: number) => {
      const s = "${" + column.field +"}";
      const x = variableNames[idx];
      variableMap[column.field] = x;
      expression = expression.replace(s, x);
    });

    let code;
    try {
      code = compile(expression);
    } catch (e) {
      console.error(e);
      return table;
    }

    const idx: { [key: string | number]: any } = {};

    const randomRange = (start: number, end: number) => {
      return Math.floor(Math.random() * (end - start + 1)) + start;
    }

    return table.map<Row>((row: Row, i) => {
      const scope: { [key: string]: any; } = {
        movingAverage: function (col: string, orderby: string, range: number) {
          if (!idx?.[orderby]) {
            const isDesc = orderby.startsWith('-');
            orderby = isDesc ? orderby.substring(1) : orderby;
            idx[orderby] = table.map((row: Row, i: number) => {
              return {i, row};
            }).sort((a: any, b: any) => {
              return a.row[orderby] > b.row[orderby] ? 1 : -1;
            }).map(({i}, j, arr) => {
              if (j < range) {
                return null;
              }
              const ret = arr.slice(j - range, j).reduce((acc, {row}) => acc + (row[col] as number), 0) / range;
              idx[i] = ret;
              return ret;
            });
          }
          return idx?.[i] ?? null;
        },

        randRange: function (start: number, end: number) {
          return randomRange(start, end);
        },
        rand: function () {
          return Math.random();
        }
      };
      Object.keys(variableMap).forEach((key: string) => {
        scope[variableMap[key]] = row[key];
      });
      let result: any;
      try {
        result = code.evaluate(scope);
      } catch (e) {
        console.error(e);
      }
      return { ...row, [step.name]: result };
    });
  }

  processJoin(table: Table, step: JoinStep): Table {
    // TODO implement join
    return table;
  }

  processQuery(table: Table, columns: Array<Column>, steps: Array<QueryStep>): { columns: Array<Column>, table: Table } {
    const stepColumns = processColumns(columns, steps);
    const newTable = steps.reduce<Table>((table: Table, step: QueryStep, idx: number) => {
      try {
        const columns = stepColumns[idx];
        switch (step.type) {
          case 'filter':
            return this.processFilter(table, columns, step as FilterStep);
          case 'group':
            return this.processGroup(table, columns, step as GroupStep);
          case 'function':
            return this.processFunction(table, columns, step as FunctionStep);
          case 'join':
            return this.processJoin(table, step as JoinStep);
          default:
            return table;
        }
      } catch (e) {
        console.error(e);
        return table;
      }
    }, table);
    return { columns: stepColumns[stepColumns.length - 1], table: newTable };
  }
}

export type { QueryStep, Column, Table, FilterStep, GroupStep, FunctionStep, JoinStep };

export const processQuery = (table: Table, columns: Array<Column>, steps: Array<QueryStep>) => {
  return new QueryEngine().processQuery(table, columns, steps);
}
