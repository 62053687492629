import React from "react";
import { Link } from "react-router-dom";
import apiFetch from "../api";
import { useQuery } from "react-query";

const Home = () => {
    const {data} = useQuery("hello", async () => {
        return await apiFetch("/api/hello/");
    })
    return (
        <div>
            <h2>Home</h2>
            <p>
                This is a demo of React Query. Click on the Users link above to see
                the demo.
            </p>
            {data && <p>API: {data.message}</p>}
            <Link to="/users">Users</Link>
        </div>
    )
};

export default Home;
