import React, { useEffect, useMemo, useRef, useState } from "react";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useFieldArray, useForm } from "react-hook-form";
import Select from "./Select";
import Input from "./Input";
import IconBtn from "./buttons/IconBtn";
import TextBtn from "./buttons/TextBtn";
import { MdAdd, MdClear, MdDragIndicator, MdExpandLess, MdExpandMore } from "react-icons/md";


const SeriesForm = ({columns, control, register, name, onRemove}) => {
  const options = useMemo(() => {
    if (!columns) {
      return [];
    }
    return columns.map(({field, headerName}) => ({
      value: field,
      label: headerName || field,
    }));
  }, [columns]);
  return (
    <div className="flex mt-2">
      <div className="mr-2">
        <Select {...register(`${name}.y`)} options={options} />
      </div>
      <div className="mr-2">
        <Select {...register(`${name}.type`)} options={[
          { value: 'line', label: 'Line' },
          { value: 'scatter', label: 'Scatter' },
          { value: 'column', label: 'Column' },
          { value: 'bar', label: 'Bar' },
          { value: 'pie', label: 'Pie' },
        ]} />
      </div>
      <TextBtn onClick={onRemove}><MdClear /></TextBtn>   
    </div>
  );
}


const ChartControls = ({ name, columns, register, control }) => {

  const { fields, append, remove } = useFieldArray({
    control,
    name: `${name}.series`,
  });

  return (
    <>
      <div>
        <label>Title</label>
        <Input {...register(`${name}.title`)} />
      </div>
      <div className="mt-2">
        <label>X Axis</label>
        <Select {...register(`${name}.x`)} options={columns.map(({ field, headerName }) => ({
          value: field,
          label: headerName || field,
        }))} />
      </div>
      <div className="mt-2">
        <label>Series</label>
        {fields.map((field, index) => (
          <SeriesForm key={field.id} columns={columns} control={control} register={register} name={`${name}.series.${index}`} onRemove={() => remove(index)} />
        ))}
      </div>
      <div className="mt-2">
        <IconBtn onClick={() => append({ y: columns[1]?.field, type: "line" })}><MdAdd /></IconBtn>
      </div>
    </>
  )
}

export default ChartControls;

