import { useQuery } from "react-query"
import apiFetch from "../api"
import { useParams } from "react-router-dom";
import Tabs from "../Components/Tabs";
import { useState } from "react";
import ArticleCard from "../Components/ArticleCard";
import Podcast from "../Components/Podcast";

const NewsItem = () => {
  const [tab, setTab] = useState("articles");
  const { newsId } = useParams();
  const {data: podcast} = useQuery(["podcasts", newsId], async () => {
    return await apiFetch(`/api/podcasts/${newsId}/`);
  }, { keepPreviousData: true });
  return podcast && (
    <div className="m-auto max-w-4xl">
      <h2>{podcast.title}</h2>
      <div className="my-3">
      <Podcast id={newsId} />
      </div>
      <Tabs tabs={[
        { id: "articles", name: "Articles" },
        { id: "script", name: "Script" },
      ]} activeTab={tab} setActiveTab={setTab} />
      <div className="h-[calc(100vh-200px)] flex flex-col">
        <div className="grow p-2 w-100 overflow-auto">
          {tab === "script" && (
            <>
              {podcast?.script?.split("\n")?.filter(l => !!l)?.map((line, index) => (
                <div className="mt-4">
                  <p key={index}>
                    {line}
                    <br />
                  </p>
                </div>
              ))}
            </>
          )}
          {tab === "articles" && (
            <>
              {podcast?.articles?.map((article, index) => (
                <ArticleCard key={index} article={article} />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default NewsItem;

