import React from "react";
import { Link } from "react-router-dom";

const Users = () => {
    return (
        <div>
            <h2>Users</h2>
            <p>
                This is a demo of React Query. Click on the Home link above to see
                the demo.
            </p>
            <Link to="/">Home</Link>
        </div>
    )
};

export default Users;
