import { useEffect, useMemo, useRef } from "react";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


const Chart = ({ data, columns, name, control, register, watch }) => {
  const chartRef = useRef(null);

  const x = watch(`${name}.x`);
  const series = watch(`${name}.series`);
  const title = watch(`${name}.title`);
  const seriesJson = JSON.stringify(series);

  useEffect(() => {
      if (chartRef.current) {
          chartRef.current.chart.reflow();
      }
  }, [x, series, title])

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.reflow();
    }
  }, [])

  const format = (value, type, key, compact = true) => {
    if (type === "date") {
      if (key === "year") {
        return value;
      }
      if (key === "month") {
        return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][value - 1];
      }
      if (key === "weekday") {
        return ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"][value - 1];
      }
      const dateFormatter = new Intl.DateTimeFormat('en-US');
      try {
        return dateFormatter.format(new Date(value));
      } catch (e) {
        console.error(e);
        return value;
      }
    }
    if (type === "number") {
      const options = {}
      if (compact) {
        options.notation = 'compact';
      }
      const numberFormatter = new Intl.NumberFormat('en-US', options);
      try {
        return numberFormatter.format(value);
      } catch (e) {
        console.error(e);
        return value;
      }
    }
    if (type === "currency") {
      const options = {
        style: 'currency',
        currency: 'USD',
      }
      if (compact) {
        options.notation = 'compact';
      }
      const currencyFormatter = new Intl.NumberFormat('en-US', options);
      try {
        return currencyFormatter.format(value);
      } catch (e) {
        console.error(e);
        return value;
      }
    }
    return value;
  }
  const options = useMemo(() => {
    try {
      const xType = columns.find((c) => c.field === x)?.type;
      const sortedData = data.sort((a, b) => a[x] - b[x]);

      return {
        title: {
          text: title
        },
        colors: [
          '#8b5cf6',
          '#cf46dc',
          '#fb31ba',
          '#ff2e95',
          '#ff4771',
          '#ff684e',
          '#ff882d',
          '#ffa600',
        ],
        xAxis: {
          categories: sortedData.map(d => d[x]),
          title: {
            text: columns.find((c) => c.field === x)?.name
          },
          labels: {
            formatter: function () {
              try {
              let ret = format(this.value, xType, x);
              return ret
              } catch (e) {
                console.error(e);
                return this.value;
              }
            }
          }
        },
        series: series.map((s) => ({
          data: sortedData.map(d => {
            const val = d[s.y]
            if (typeof val === 'string') {
              return parseFloat(val)
            }
            return val;
          }),
          name: columns.find((c) => c.field === s.y)?.name,
          type: s.type,
        })),
        tooltip: {
          formatter: function () {
            return ` ${format(this.x, xType, x, false)}}</b>`;
          }
        }
      }
    } catch (e) {
      console.error(e);
      return {}
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, data, x, title, seriesJson])

  return (
    <div className="mt-2">
      <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} containerProps={{ style: { height: "100%" } }} />
    </div>
  )
}

export default Chart;
