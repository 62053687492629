import React from 'react';

const Breadcrumbs = ({children}) => (
    <nav className="flex" aria-label="Breadcrumb">
        <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
            {children}
        </ol>
    </nav>
);

Breadcrumbs.Separator = () => (
    <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
    </svg>
);

Breadcrumbs.Item = ({children}) => (
    <li className="inline-flex items-center">
        {children}
    </li>
);

export default Breadcrumbs;
