import { useMutation, useQuery, useQueryClient } from "react-query"
import apiFetch from "../api"
import { Link, useNavigate } from "react-router-dom";

const CanvasList = () => {
  const { data: canvas } = useQuery(["canvas"], async () => {
    return await apiFetch("/api/canvas/");
  }, { keepPreviousData: true });

  return (
    <div>
      <h2 className="text-lg mb-4">Canvas</h2>
      <div className="grid grid-cols-1">
        <ul>
          {canvas?.results && canvas?.results?.map(c => (
            <li key={c.id}>
              <Link to={`/canvas/${c.id}`}>
                {c.name || "Untitled"} <span className="italic text-gray-400">{c.id}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      {canvas?.results?.length === 0 && <div className="text-center">No Canvases found found</div>}
      <Link className="text-primary-600" to="/canvas/new">Create New</Link>
    </div>
  )
}

export default CanvasList;

