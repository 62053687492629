import React, { useMemo, useState } from "react";
import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import Query from "../Components/Query";
import { processQuery } from "../QueryEngine";
import Tabs from "../Components/Tabs";
import Chart from "../Components/Chart";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { FaChartLine, FaSave, FaTable } from "react-icons/fa";
import TextBtn from "../Components/buttons/TextBtn";
import Select from "../Components/Select";
import ChartControls from "../Components/ChartControls";
import FileUpload from "../Components/FileUpload";
import { useMutation, useQuery, useQueryClient} from "react-query";
import apiFetch from "../api";
import { useParams, useNavigate } from "react-router-dom";
import Input from "../Components/Input";
import InlineInput from "../Components/InlineInput";


const useRows = (file) => {

  const { data: rows } = useQuery(["rows", file], async () => {
    return await apiFetch(`/api/rows/?file=${file}`);
  }, {
    enabled: !!file
  });

  const df = useMemo(() => {
    if (!rows) {
      return null;
    }
    return rows?.results?.map((row) => row.row);
  }, [rows]);
  return { df, rows };
}


const Sheet = ({ name, register, control, handleSubmit, watch, children }) => {

  const file = watch(`${name}.file`);
  const query = watch(`${name}.steps`);
  const queryJson = JSON.stringify(query);
  const { df } = useRows(file);

  const baseColumns = useMemo(() => {
    if (!df) {
      return [];
    }
    return Object.keys(df[0]).map((key) => {
      const sample = df[0][key];
      if (!isNaN(sample)) {
        return { field: key, name: key, type: "number" };
      }
      const dt = new Date(sample);
      if (dt instanceof Date && !isNaN(dt)) {
        return { field: key, name: key, type: "date" };
      }
      return { field: key, name: key, type: "string" };
    });
  }, [df]);

  const { columns, table: data } = useMemo(() => {
    if (!df) {
      return {
        columns: [],
        table: []
      };
    }
    if (query.length > 0) {
      const ret = processQuery(df, baseColumns, query);
      return ret
    }
    return {
      columns: baseColumns,
      table: df
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [df, queryJson, baseColumns]);

  console.log("Data", data);

  return (
    <>
      <div className="flex-1">
        {children}
        <div className="mt-2 z-0">
          {df ? (
            <div className="h-[calc(100vh-74px)] ag-theme-quartz">
              <AgGridReact columnDefs={columns} rowData={data} />
            </div>
          ) : (
            <Controller
              name={`${name}.file`}
              control={control}
              render={({ field }) => (
                <FileUpload {...field} />
              )} />
          )}
        </div>
      </div>
      {/*Right Side Panel*/}
      <div className="ml-2 -mr-4 -my-4 w-96 h-screen bg-slate-200 border-l border-gray-300">
        <Query name={name} model={baseColumns} register={register} control={control} handleSubmit={handleSubmit} watch={watch} />
      </div>
    </>
  );
}


const SheetChart = ({ name, register, control, handleSubmit, watch, children }) => {
  const sheets = watch("sheets");
  const sheetOptions = sheets.filter((s) => s.type === 'sheet').map((sheet, index) => ({
    value: sheet.sheetId,
    label: sheet.name
  }));
  const selectedSheet = watch(`${name}.sheet`);
  const { steps: query, file } = sheets.find(s => s.sheetId = selectedSheet) || {};
  const { df } = useRows(file);
  const queryJson = JSON.stringify(query);

  const baseColumns = useMemo(() => {
    if (!df) {
      return [];
    }
    return Object.keys(df[0]).map((key) => {
      const sample = df[0][key];
      if (!isNaN(sample)) {
        return { field: key, name: key, type: "number" };
      }
      const dt = new Date(sample);
      if (dt instanceof Date && !isNaN(dt)) {
        return { field: key, name: key, type: "date" };
      }
      return { field: key, name: key, type: "string" };
    });
  }, [df]);

  const { columns, table: data } = useMemo(() => {
    if (!df) {
      return {
        columns: [],
        table: []
      };
    }
    if (query.length > 0) {
      const ret = processQuery(df, baseColumns, query);
      return ret
    }
    return {
      columns: baseColumns,
      table: df
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [df, queryJson, baseColumns]);

  return (
    <>
      <div className="flex-1">
        {children}
        <div className="mt-2 z-0">
          <Chart data={data} columns={columns} watch={watch} name={name} control={control} register={register} />
        </div>
      </div>
      <div className="ml-2 -mr-4 -my-4 w-96 h-screen bg-slate-200 border-l border-gray-300">
        <div className="w-full p-2 relative mb-3 mb:mb-0">

          <div className="max-h-[calc(100vh-100px)] overflow-auto">
            <div className='w-full bg-white p-2 rounded mb-2'>

              <Select {...register(`${name}.sheet`)} options={sheetOptions} />
              <ChartControls name={name} data={data} columns={columns} register={register} control={control} />

            </div>
          </div>
        </div>
      </div>
    </>
  )
}


const SheetContainer = ({ name, register, control, handleSubmit, watch, children }) => {
  const sheetType = watch(`${name}.type`) || 'sheet';

  return (
    <>
      {sheetType === 'sheet' && (
        <Sheet name={name} register={register} control={control} handleSubmit={handleSubmit} watch={watch}>
          {children}
        </Sheet>
      )}
      {sheetType === 'chart' && (
        <SheetChart name={name} register={register} control={control} handleSubmit={handleSubmit} watch={watch} >
          {children}
        </SheetChart>
      )}
    </>
  )
};

const Canvas = ({ defaultValue, onSubmit }) => {
  const { register, control, handleSubmit, watch } = useForm({ defaultValues: defaultValue });
  const [tab, setTab] = useState(1);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sheets',
  });

  const state = watch();
  console.log("State", state);

  const selectedSheetIndex = fields.findIndex((field) => field.sheetId === tab);

  return (
    <div className="flex">
      <SheetContainer key={selectedSheetIndex} name={`sheets.${selectedSheetIndex}`} register={register} control={control} handleSubmit={handleSubmit} watch={watch} >
        <div className="">
          <InlineInput {...register(`name`)}  />
        </div>
        <div className="mt-3">
          <Tabs tabs={
            fields.map((field) => ({
              id: field.sheetId,
              name: field.name || `${field.type} ${field.sheetId}`
            }))
          } activeTab={tab} setActiveTab={setTab} >
            <TextBtn onClick={() => append({ steps: [], type: "sheet", sheetId: fields.length + 1 })}>
              <FaTable />
            </TextBtn>
            <TextBtn onClick={() => append({ type: "chart", sheetId: fields.length + 1, sheet: fields.find((s) => s.type === 'sheet')?.sheetId })}>
              <FaChartLine />
            </TextBtn>
            <TextBtn onClick={() => handleSubmit(onSubmit)()}>
              <FaSave />
            </TextBtn>
          </Tabs>
        </div>
      </SheetContainer>
    </div>
  )
}

const CanvasWrapper = () => {
  const { canvasId: defaultCanvasId } = useParams();
  const nav = useNavigate();
  const queryClient = useQueryClient();

  const [_canvasId, setCanvasId] = useState(null);
  const canvasId = useMemo(() => {
    return _canvasId || defaultCanvasId;
  }, [defaultCanvasId, _canvasId]);

  const createCanvas = useMutation(async (data) => {
    return await apiFetch(`/api/canvas/`, {
      method: 'POST',
      body: JSON.stringify({name: "New", content: data})
    });
  });

  const updateCanvas = useMutation(async (data) => {
    return await apiFetch(`/api/canvas/${canvasId}/`, {
      method: 'PATCH',
      body: JSON.stringify({content: data})
    });
  });

  const handleSave = async (data) => {
    if (canvasId) {
      await updateCanvas.mutateAsync(data);
    } else {
      const res = await createCanvas.mutateAsync(data);
      setCanvasId(res.id);
      nav(`/canvas/${res.id}`, { replace: true });
    }
    queryClient.invalidateQueries('canvas');
  }

  const {data: canvas, isLoading} = useQuery(['canvas', canvasId], async () => {
    return await apiFetch(`/api/canvas/${canvasId}/`);
  }, {
    enabled: !!canvasId,
    select: (data) => data.content
  });
  const defaultCanvas = { sheets: [{ sheetId: 1, name: "Sheet 1", type: "sheet", steps: [] }] };
  return (
    <div className="flex-1 p-4">
      {isLoading && <div>Loading...</div>}
      {(!isLoading || !canvasId) && <Canvas defaultValue={canvas || defaultCanvas} onSubmit={handleSave} />}
    </div>
  )
}

export default CanvasWrapper;
