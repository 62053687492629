

const ArticleCard = ({ article }) => {
  return (
    <div className="card mt-3 max-w-screen-sm">
      {article.image && <img src={article.image} alt={article.title} />}
      <h4><a href={article.url} target="_blank" rel="noreferrer">{article.title}</a></h4>
      <span className="byline"><a href={article.url}>{article.domain}</a></span>
      <p>{article.description}</p>
    </div>
  );
}

export default ArticleCard;