import React from "react";
import {
    FaDiceOne, FaDiceTwo, FaDiceThree, FaDiceFour, FaDiceFive, FaDiceSix
} from "react-icons/fa";


const Dice = ({spec}) => {
    const diceFaces = [
        <FaDiceOne />,
        <FaDiceTwo />,
        <FaDiceThree />,
        <FaDiceFour />,
        <FaDiceFive />,
        <FaDiceSix />,
    ]
    if (spec < 1 || spec > 6) {
        return spec;
    }

    return (
        <div className="text-2xl mt-1 p-3 text-primary-600">
            {diceFaces[spec - 1]}
        </div>
    )
}

export default Dice;