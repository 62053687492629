import React from "react";

const IconBtn = ({ children, ...props }) => (
  <button {...props} className="mx-1 text-primary-500 border border-primary-500 hover:border-primary-500 hover:text-primary-700 bg-white font-bold py-2 px-4 rounded">
    {children}
  </button>
);

export default IconBtn;

