import { useMutation, useQuery, useQueryClient } from "react-query";
import apiFetch from "../api";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import Breadcrumbs from "../Components/Breadcrumbs";
import Chart from "./Chart";
import Dice from "./Dice";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

const SENDERS = {
  1: "Me",
  2: "Bot",
  3: "System"
}

const Collapsible = ({ title, children, defaultOpen = false }) => {
  const [open, setOpen] = useState(defaultOpen);
  return (
    <>
      <div className="flex justify-between w-max max-w-full cursor-pointer" onClick={() => setOpen(!open)}>
        <div>{title}</div>
        <div>{open ? "▲" : "▼"}</div>
      </div>
      {open && <div>{children}</div>}
    </>
  );
}

const Chat = () => {
  const { chatId } = useParams();
  const queryClient = useQueryClient();
  const [text, setText] = useState("");
  const { data: chat } = useQuery(["chats", chatId], async () => {
    return await apiFetch(`/api/chats/${chatId}/`);
  });
  const { data: messages } = useQuery(["messages", chatId], async () => {
    return await apiFetch(`/api/messages/?ordering=-created_at&chat=${chatId}`);
  }, { keepPreviousData: true });

  const sendMessage = useMutation(
    async (message) => {
      return await apiFetch(`/api/messages/`, {
        method: "POST",
        body: JSON.stringify({
          text: message,
          chat: chatId,
        })
      });
    },
    {
      onMutate: async (message) => {
        // Cancel any outgoing refetches
        // (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries({ queryKey: ['messages', chatId] })

        // Snapshot the previous value
        const messages = queryClient.getQueryData(['messages', chatId])

        // Optimistically update to the new value
        queryClient.setQueryData(['messages', chatId], (old) => ({ ...old, results: [{ id: -1, text: message, chat: chatId, sender: 1 }, ...old.results] }));

        // Return a context object with the snapshotted value
        return { prev: messages }
      },
      // If the mutation fails,
      // use the context returned from onMutate to roll back
      onError: (err, message, context) => {
        console.log(err);
        setText(message);
        queryClient.setQueryData(['messages', chatId], context.prev)
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ['messages', chatId] })
      },
    }
  )

  return (
    <div>
      <div className="mb-4">
        <Breadcrumbs>
          <Breadcrumbs.Item>
            <Link to="/">Home</Link>
          </Breadcrumbs.Item>
          <Breadcrumbs.Separator />
          <Breadcrumbs.Item>
            <Link to="/chats">Chats</Link>
          </Breadcrumbs.Item>
          <Breadcrumbs.Separator />
          <Breadcrumbs.Item>
            <Link to={`/chats/${chatId}`}>{chat?.name}</Link>
          </Breadcrumbs.Item>
        </Breadcrumbs>
      </div>
      <div className="h-[calc(100vh-94px)] flex flex-col m-auto max-w-4xl">
        <div className="grow p-2 w-100 overflow-auto flex flex-col-reverse">
          {sendMessage.isLoading && (
            <>
              <div className={`mt-2 p-2 w-max max-w-full rounded-lg bg-primary-300`}>
                {SENDERS[2]}: <i className="loading">Typing</i>
              </div>
            </>
          )}
          {chat && messages?.results && messages?.results?.map(message => {
            if (message.metadata?.name === 'vizualize') {
              const spec = JSON.parse(message.metadata?.data || message.text);
              return <Chart key={`chart-${message.id}`} spec={spec} model={chat.data_model} />
            }
            if (message.metadata?.name === 'rolldice') {
              const spec = JSON.parse(message.text);
              return <Dice key={`dice-${message.id}`} spec={spec} />
            }
            if(message.sender === 3) {
              return (
                <div key={message.id} className={`mt-2 p-2 w-max max-w-full rounded-lg bg-gray-300`}>
                  <Collapsible title={SENDERS[message.sender]} >
                    <Markdown remarkPlugins={[remarkGfm]}>{message.text}</Markdown>
                  </Collapsible>
                </div>
              )
            }
            return (
              <div key={message.id} className={`mt-2 p-2 w-max max-w-full rounded-lg ${message.sender === 1 ? 'bg-gray-300' : ''}`}>
                <Markdown remarkPlugins={[remarkGfm]}>{message.text}</Markdown>
              </div>
            )
          })}
          {messages?.length === 0 && <div className="text-center">No messages found</div>}
        </div>
        <div className="flex flex-col mt-3">
          <textarea rows={3} className="text-input grow mb-3" type="text" value={text} onChange={(e) => setText(e.target.value)} onKeyDown={(e) => {
            // allow shift enter to make a new line
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              sendMessage.mutate(text);
              setText("");
            }
          }} />
          <div className="text-right">
            <button className="btn-primary" onClick={() => {
              sendMessage.mutate(text);
              setText("");
            }}>Send</button>
          </div>
        </div>
      </div>
    </div>
  )

}

export default Chat;
